.buyback-container {
    padding: 30px 0px 30px 0px;
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .company-logo {
    width: 80px;
    height: 80px;
  }
  
  .company-title {
    font-size: 24px;
  }
  
  .about-section,
  .buyback-details-section,
  .buyback-ratio-section,
  .financials-section,
  .participation-section,
  .registrar-contact-section,
  .company-contact-section {
    margin-top: 20px;
  }
  
  .table-row {
    border-bottom: 1px solid #ddd;
  }



  
  
  .table-container {
    width: 100%;
    overflow-x: auto; /* For responsiveness */
    margin: 20px 0;
  }
  
  .table-title {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    color: #333;
  }
  
  .financials-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
  }
  
  .financials-table th, 
  .financials-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: center;
    font-size: 14px;
    min-width: 150px; /* Ensures proper width for columns */
  }
  
  .financials-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .financials-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .financials-table th {
    background-color: #333;
    color: white;
  }
  
  .financials-table td {
    color: #555;
  }
  
  .financials-table td:first-child, 
  .financials-table th:first-child {
    width: 200px; /* Ensures the first column (Period Ended) is wider */
  }
  
  @media (max-width: 768px) {
    .financials-table th, .financials-table td {
      padding: 8px 10px;
      font-size: 12px;
      min-width: 100px; /* Adjust for smaller screens */
    }
  
    .financials-table td:first-child, 
    .financials-table th:first-child {
      width: 150px; /* Adjust first column width for smaller screens */
    }
  }
  