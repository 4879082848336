/* 
.card {
    width: 100%;
    max-width: 600px;
    margin: 1rem auto;
    padding: 1.5rem;
    background-color: #ffffff;
    border: 1px solid #e9e9eb;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  

  .card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
  }

  .card-links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  

  .card-link {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #0073e6;
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .card-link:hover {
    background-color: #f0f8ff;
    border-color: #0073e6;
  }
  
 */


 .app-container {
    display: flex;
    flex-direction: column; /* Column layout for mobile view */
    /* padding: 2rem; */
    justify-content: space-between;
    /* gap: 1rem; */
/* align-items: center; */
/* align-content: center;
justify-items: center; */
    width: 100%;
    max-width: 1300px;

   
    /* background-color: #f5f5f5; Modern light background for clean design */
}

.benefits-container {
    /* width: 100%;  */
    /* margin: 1rem; */
    /* background-color: #ffffff; Bright white for contrast and modern look */
    border-radius: 8px; 
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);  */
    /* margin-bottom: 2rem; */
    /* padding: 1.5rem; */
    padding: 0 1.5rem 1.5rem 1.5rem;
    transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
    /* border: 1px solid #e9e9eb; Subtle border for modern touch */
}

.benefit-item {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
}

.benefit-item:last-child {
    border-bottom: none; /* Remove border for the last item */
}

.benefit-item h3 {
    font-size: 1.25rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 0.5rem;
}

.benefit-item p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 0;
}

/* Sidebar container styling */
.sidebar-container {
    /* width: 100%; */
    /* margin-bottom: 2rem; */
    /* background-color: #f4f5f7; Light background for a modern look */
    /* border: 1px solid #e9e9eb; */
    padding: 0 1.5rem 1.5rem 1.5rem;
    border-radius: 8px;
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); Subtle shadow for depth */
    /* margin-bottom: 2rem; */
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    font-size: 1rem;
    font-family: GrowwSans, NotoSans, system-ui;
}

/* Sidebar heading */
.sidebar-container h3 {
    font-size: 1.25rem;
    color: #44475b;
    font-weight: 700;
    /* margin-bottom: 1rem; */
    /* padding: 1rem; */
     /* border-top: 1px solid #e9e9eb; 
    border-left: 1px solid #e9e9eb; 
    border-right: 1px solid #e9e9eb; */
    /* border-bottom: 1px solid #e9e9eb;  */
}

/* List items - Table-like structure with borders */
.sidebar-container ul {
    list-style: none;
    padding: 0;
    /* border-radius: 8px; */
    border-top: 1px solid #e9e9eb; 
    /* border-left: 1px solid #e9e9eb; 
    border-right: 1px solid #e9e9eb; 
    border-bottom: 1px solid #e9e9eb;  */
  
}

.sidebar-container li {
    display: grid;
    /* grid-template-columns: 1fr 3fr; 2-column grid: icon and text */
    align-items: center;
    gap: 1rem; /* Gap between columns */
    /* margin-bottom: 1rem; */
    /* border-radius: 8px; */
    /* color: #0073e6; */
    color: #44475b;
    cursor: pointer;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    padding: 0.7rem;
    /* border-radius: 8px; */
    /* padding: 1rem; */
    /* border-top: 1px solid #e9e9eb; 
    border-left: 1px solid #e9e9eb; 
    border-right: 1px solid #e9e9eb; 
    border-bottom: 1px solid #e9e9eb; */

    border-top: none; /* Remove the top border */
    border-left: 1px solid #e9e9eb; /* Left border */
    border-right: 1px solid #e9e9eb; /* Right border */
    border-bottom: 1px solid #e9e9eb; 
  
}

.sidebar-container li:hover {
    /* text-decoration: underline; */
    /* color: #005bb5; */
    background-color:#cae1fa ;
}

.sidebar-container li i {
    font-size: 1.5rem;
    color: #0073e6;
    transition: color 0.3s ease-in-out;
}

.sidebar-container li:hover i {
    color: #005bb5;
}

/* Sub-menu styling */
.sidebar-container ul ul {
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
    color: #666; /* Subtle color for sub-menu items */
}

.sidebar-container ul ul li {
    color: #666; /* Subtle color for sub-menu items */
}

.sidebar-container ul ul li:hover {
    color: #333;
}

/* Toggle button styling for mobile */
.sidebar-toggle-btn {
    display: none;
    background-color: #0073e6;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.sidebar-toggle-btn:hover {
    background-color: #005bb5;
}


@media (min-width: 768px) {
    .app-container {
        flex-direction: row; /* Row layout for larger screens */
    }

    .benefits-container {
        width: 70%; /* Benefits take 70% of the space on desktop */
    }

    .sidebar-container {
        width: 30%; /* Sidebar takes 30% of the space on desktop */
        margin-top: 0; /* Remove top margin for desktop */
    }

    .sidebar-toggle-btn {
        display: none; /* Hide the toggle button on large screens */
    }
}








.bc785BreadText {
    font-size: 12px;
    color: #7c7e8c;
  }
  
  .bc785Link {
    color: #007bff; /* Or any desired link color */
    text-decoration: none;
  }
  
  .bc785BreadSpace {
    margin: 0 8px;
    color: #7c7e8c;
  }
  
  .bc785Last {
    font-size: 12px;
    line-height: 1.2;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* For truncating the long text */
  }
  
  .valign-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: row;
    /* flex-d */
  }

  .sb989MainDiv {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .sb989MainDiv.show {
    opacity: 1;
    transform: scale(1);
  }
  
  .sb989ShareButton {
    transition: transform 0.2s ease;
  }
  
  .sb989ShareButton:hover {
    transform: scale(1.1);
  }
  
  
  /* .sb989MainDiv {
    display: flex;
    align-items: center;
    gap: 8px; 
  } */
  
  .sb989ShareIcon {
    fill: #7c7e8c; 
  }
  
  /* .sb989ShareButton {
    width: 35px;
    height: 35px;
    cursor: pointer;
  } */
  
  .lazyload-wrapper img {
    width: 35px;
    height: 35px;
  }
  
  a.sb989ShareButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  a.sb989ShareButton img {
    width: 35px;
    height: 35px;
  }
  
  .nevigationlink {
    text-decoration: none;
    color:#8f8f8f;
  }
  .nevigationlink:hover {
    text-decoration: underline;
    color:#8f8f8f;
    /* color: #005bb5; */
    /* background-color:#cae1fa ; */
}







/* FAQ Container */
.faq-container1 {
  /* max-width: 800px; */
  /* margin: 40px auto; */
  /* padding: 20px; */
  /* font-family: "Arial", sans-serif; */
  /* color: #333; */
  /* background: #fff; */
}

.faq-header1 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

/* FAQ List */
.faq-list1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* FAQ Item */
.faq-item1 {
  /* background: #fff; */
  /* border-top: 1px solid #ddd; */
  border-bottom: 1px solid #e9e9eb;
  /* padding: 15px 0 15px 0; */
  /* border-radius: 5px; */
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
}

.faq-item1:hover {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.faq-item1.active {
  border-color: #007bff;
  /* border-bottom: 1px solid #e9e9eb; */
}

/* Question Section */
.faq-question1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 15px 0;
  font-size: 16px;
  font-weight: 500;
  /* color: #333; */
   /* border-bottom: 1px solid #e9e9eb; */
}

.faq-icon1 {
  font-size: 16px;
  font-weight: bold;
  /* color: black; */
  margin-left: 20px;
}

/* Answer Section */
.faq-answer1 {
  padding: 0px;
  margin: 0px;
  font-size: 14px;

  max-height: 0; /* Initially hidden */
  opacity: 0; /* Initially transparent */
  transition:  max-height 0.3s ease, opacity 0.3s ease; /* Smooth animation */
  /* color: #555; */
  /* background: #fff; */
  /* border-top: 1px solid #ddd; */
  /* line-height: 1; */
}


/* .faq-answer1 {
  max-height: 0; 
  opacity: 0; 
  transition: max-height 0.3s ease, opacity 0.3s ease; 
} */

.faq-answer1.open {
  max-height: 700px; /* Adjust to fit your content */
  opacity: 1; /* Fully visible */
}


/* Responsive Design */
@media (max-width: 768px) {
 

  .faq-header1 {
    font-size: 24px;
  }

  .faq-question1 {
    font-size: 15px;
  }

  .faq-answer1 {
    font-size: 13px;
  }
}









.benefits-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0; /* Adjust the spacing above and below the table */
  font-size: 16px;
  text-align: left; /* Align text to the left */
  color: #44475b; /* Match table text color with your font */
}

.benefits-container table th,
.benefits-container table td {
  border: 1px solid #e9e9eb; /* Add a border to cells */
  padding: 12px; /* Adjust padding inside cells */
  width: 100%;

  word-wrap: break-word; /* Enable wrapping of long words */
  word-break: break-word; /* Break words if necessary */
  white-space: normal; /* Allow text to wrap to the next line */
}

.benefits-container table th {
  background-color: #f4f4f4; /* Add a subtle background to header cells */
  font-weight: bold; /* Make header text bold */
}

.benefits-container table tr:nth-child(even) {
  background-color: #f9f9f9; /* Add zebra striping for rows */
}

/* table tr:hover {
  background-color: #f1f1f1; 
} */


/* styles.css */

/* Style for all <a> tags */
.benefits-container a {
  color: blue; /* Default color */
  text-decoration: underline; /* Optional: Remove underline */
}

/* Hover effect for <a> tags */
.benefits-container a:hover {
  color: #ff7847; /* Hover color */
  text-decoration: underline; /* Optional: Add underline on hover */
}


/* Responsive Table for Mobile Devices */
@media screen and (max-width: 768px) {
  .benefits-container table {
    font-size: 14px; /* Smaller font size for small screens */
  }

  .benefits-container table th, .benefits-container table td {
    padding: 8px; /* Smaller padding for mobile */
  }
}
