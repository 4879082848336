/* Link styles */
.link-style {
    text-decoration: none;
    color: black;
    /* display: block; */
    width: auto;
    /* max-width: 1200px; */
    /* margin: 16px auto; */
    padding: 15px 0 0 0;
    /* border-radius: 12px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* background-color: #ffffff; */
    /* transition: transform 0.3s ease; */
    overflow: hidden;
  }
  
  /* Grid container */
  .grid-container {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(5, 1fr); /* 5 columns by default */
  }
  
  @media (max-width: 768px) { /* For tablets */
    .grid-container {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on tablets */
    }
  }
  
  @media (max-width: 480px) { /* For mobile devices */
    .grid-container {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on mobile */
    }
  }
  
  /* Grid item styles */
  .grid-item {
    display: flex;
    flex-direction: column;
    /* padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc; */
    /* background-color: #f9f9f9; */
  }
  
  .label1 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  
  .value1 {
    font-size: 14px;
    color: #666;
  }
  