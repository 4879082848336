.button-containers {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 0 0 5px 0;
    flex-wrap: wrap; /* Allow wrapping for small screens if necessary */
  }
  
  .filter-btns {
    /* background-color: #007bff; */
    /* color: white; */
    /* border: none; */
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    flex-grow: 1; /* Makes buttons flexible */
    text-align: center; /* Ensures text stays centered */


font-weight: bold;
    background-color: white;
    color: #00796b;
  /* background-color: white; 
  color: #007BFF; */
  border: 1px solid #00796b;
  /* padding: 5px 20px; */
  /* border-radius: 8px; */
  /* font-size: 14px; */
  /* font-weight: 520; */
  /* cursor: pointer; */
  /* transition: font-size 0.3s ease, background-color 0.3s ease, transform 0.3s ease; */
  /* transition: background-color 0.3s ease; */
  outline: none;
  }
  
  .filter-btns:hover {
    /* background-color: #01685c; */
    color:#00796b;
    box-shadow: inset 0px 0px 50px -21px #00796b;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    outline: none;
  }
  
  .filter-btns.active {
    background-color: #00796b;
    color:white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
    .button-containers {
      gap: 5px; /* Reduce the gap on smaller screens */
    }
  
    .filter-btns {
      padding: 10px;
      font-size: 14px;
      flex-grow: 1; /* Makes each button take equal width */
    }
  }
  




  .view-more-btns, .show-less-btns {
    background-color: #00796b;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
  }
  
  .view-more-btns:hover, .show-less-btns:hover {
    /* background-color: #0056b3; */
    background-color: #01534a;
    color:white;
    outline: none;
  }
  