.containerinfo {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    /* margin: 0px 0px; */
  }
  
  .image-div {
    width: 100%;
    max-width: 200px;
    min-width: 100px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
  }
  
  .image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
  }
  
  .text-div {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    min-width: 200px;
    width: calc(100% - 210px);
    flex: 1;
  }
  
  .text {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap:5px;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .image-div,
    .text-div {
      width: 100%;
      max-width: none;
      min-width: none;
    }
    .text-div {
      width: 100%;
    }
  }
  