/* Calendar container */
/* .calendar-container {
    max-width: 1000px; 
    width: "100%";
    margin: 0 auto;
  }
   */
  /* Event calendar container */
  .event-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  /* Custom tile container */
  .custom-tile {
    position: relative;
  }
  
  /* Date inside tile */
  .tile-date {
    display: block;
  }
  .event-dots-container {
    display: flex; /* Display children in a row */
    align-items: center; /* Align children vertically in the center */
    align-content: center;
    justify-content: center;
    justify-items: center;
    gap:2px;
    margin-bottom: -12px;
   
  }
  
  .event-dot {
    width: 7px;
    text-align: center; 
    height: 7px;
    border-radius: 50%;
    /* margin-right: 2px; */
     /* Add right margin between event dots */

  }


  
  .event-dot-plus {
    font-size:10px;
    font-weight: bold;
    /* background-color: #000; Set the background color of the "+2" indicator */
    color: #080000; /* Set the text color of the "+2" indicator */
    /* width: 20px; Set the width of the "+2" indicator */
    /* height: 20px; Set the height of the "+2" indicator */
    /* border-radius: 50%; Make it circular */
    text-align: center; /* Center the text horizontally */
    /* line-height: 20px; Center the text vertically */
    /* margin-left: 0px;  */
    /* Add left margin between the last event dot and "+2" */
  }
  
  /* Styles for mobile view (max-width: 768px is commonly used for tablets and smaller) */
@media (max-width: 768px) {
  .event-dot {
    width: 5px; /* Adjust width for mobile view */
    height: 5px; /* Adjust height for mobile view */
  }
  .event-dot-plus {
    font-size:8px;
  }
}

  .dot {
    /* position: absolute; */
    /* bottom: 2px; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    width: 8px;
    height: 8px;
    border-radius: 50%;
    /* margin-top: -5px; */
  }

  /* Width */
.scrollable-container::-webkit-scrollbar {
  width: 5px; /* Adjust thickness as needed */
}

/* Track */
.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.scrollable-container::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 4px; /* Adjust border radius as needed */
}

/* Handle on hover */
.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%);
  border-radius: 4px;
}

  
  /* Event list */
  .event-list {
    margin-left: 20px;
  }
  
  /* Individual event item */
  .event {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  /* Event title */
  .event-title {
    font-weight: bold;
  }

/* Calendar Styles */
.calendar-container {
  width: 100%; 
  margin: 0 auto;
}

.react-calendar {
  width: 100%;
  max-width: 1200px; 
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 2px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  /* border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
}


.react-calendar__month-view__weekdays__weekday {
  margin-top: -1rem;
  padding: 20px 0;
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  /* border-left: 1px solid whitesmoke; */
  /* border-right: 1px solid whitesmoke; */
  /* border:1px solid whitesmoke; */
}

abbr[title] {
  text-decoration: none;
  font-size: 13px;



}
.react-calendar__navigation {
  display: flex;
}
/* .react-calendar__tile--range {
  box-shadow: 0 0 6px 2px black;
} */

.react-calendar__navigation__arrow {
  flex-grow: 0.111;
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day{
  flex: 0 0 14.2857%;
  overflow: hidden;
  margin-inline-end: 0px;

}


.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  /* border:1px solid whitesmoke; */
}
.react-calendar__tile {
  max-width: 100%;
  padding: 20px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  /* font-size: 13px; */
  font-size: 0.95em;
  border-radius: 8px;

}


/* Styles for mobile view (max-width: 768px is commonly used for tablets and smaller) */
@media (max-width: 768px) {
  .react-calendar__tile {
    font-size: 0.833em; /* Adjust font size for mobile view */
  }
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  /* border: 1px solid whitesmoke; */
}

.react-calendar__tile--now {
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%); 
  color:white;
  /* border:1px solid rgb(107, 128, 179);
  border-radius: 10px;
  background: white;
  color:black; */
  font-size: 13px;
  border-radius: 8px;
  /* pointer-events: none; */
}
/* Styles for the active "now" tile */
.react-calendar__tile--now.react-calendar__tile--active {
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%);
  color: white;
  font-size: 13px;
  border-radius: 8px;
  /* pointer-events: none; */
}
.react-calendar__tile--now.react-calendar__tile--active:enabled:hover,.react-calendar__tile--active:enabled:focus {
  /* background: linear-gradient(90deg, rgb(177, 93, 25) 0%, rgb(222, 164, 88) 100%, rgb(247, 197, 58) 100%); */
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%);
  color: white;
  border-radius: 8px;
  /* pointer-events: none; */
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%);
  color: white;
  border-radius: 8px;
}
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day{
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%);
  color: white;
  border-radius: 8px;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%);
  color: white;
  border-radius: 8px;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
  background: linear-gradient(90deg, rgb(61, 89, 121) 0%, rgb(107, 128, 179) 49%, rgb(149, 170, 205) 100%);
  border-radius: 8px;
  color: white;
}


.react-calendar__navigation__label{
 
  font-size: 18px;
  color:#666;

 
}

.react-calendar__navigation button {
border: none;
border-radius: 8px;


}
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 24px;
} 
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 24px;
} 
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button{
  font-size: 24px;
} 
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  font-size: 24px;
} 

.react-calendar__navigation {
  display: flex;
  height: 60px;
  /* border-bottom: 1px solid whitesmoke; */

}



.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: linear-gradient(90deg, rgb(177, 93, 25) 0%, rgb(222, 164, 88) 100%, rgb(247, 197, 58) 100%);
  color: white;
  border-radius: 8px;
  /* background: white; */
  /* color:black; */
}

.react-calendar__tile--active {
  background: linear-gradient(90deg, rgb(177, 93, 25) 0%, rgb(222, 164, 88) 100%, rgb(247, 197, 58) 100%);
  color: white;
  border-radius: 8px;
  /* background: white;
  color:black; */
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend{
  background: linear-gradient(90deg, rgb(177, 93, 25) 0%, rgb(222, 164, 88) 100%, rgb(247, 197, 58) 100%);
  color: white;
  border-radius: 8px;
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day{
  background: linear-gradient(90deg, rgb(177, 93, 25) 0%, rgb(222, 164, 88) 100%, rgb(247, 197, 58) 100%);
  color: white;
  border-radius: 8px;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury {
  color: #5a5a5a;
  /* color:white; */
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
  /* pointer-events: none; */
  opacity: 0.5;

}





.modal-content {
   width: 100%;
  max-width: 1200px; 
  height: auto;
  /* border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
  padding-top: 0;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  /* padding: 0 20px 20px 0; */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  /* max-width: fit-content;
  width: auto;
  height: auto;
  overflow-y: auto;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px 20px; */
}

.modal-close-btn {
  cursor: pointer;
  background-color: #e0e0e0;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 16px;
}

.modal-close-btn:hover {
  background-color: #ccc;
}
