.timeline {
    overflow-x: auto;
  }
  
  table {
    /* border-collapse: collapse; */
  }
  
  th, td {
    /* border: 1px solid #ddd; */
    /* padding: 8px; */
    text-align: left;
  }

  tr{
    display: flex;
    flex-direction: row;
    align-content: center;

  }
  
  .highlight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: "linear-gradient(90deg, rgba(85,83,117,1) 10%, rgba(103,103,148,1) 35%, rgba(161,123,173,1) 100%)"; */
    background-color:rgb(103, 103, 148);
    padding: 0px 20px;
    /* border: 2px solid rgb(103, 103, 148); */
    color:#ffffff;
    border-radius: 10px;
    min-height: 80px;
 
    min-width: 50px;
    border: 2px solid rgb(103, 103, 148);
    box-shadow: rgb(103, 103, 148) 0px 0px 20px;
  
    /* margin-right: 15px; */
  }
  
  .cell-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #d0d0e9;
    /* border: 1px solid rgb(103, 103, 148); */
    padding: 0px 20px;
    border-radius: 10px;
    min-height: 80px;
  
    min-width: 50px;
  
    /* margin-right: 15px; */
 
   
  }
  
  .column {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .date {
    font-size: 16px;
    color:  #d0d0e9;
  }

  .date1 {
    font-size: 16px;
    color: #666;
  }


  @keyframes colorChange {
    0% { background-color: rgb(65, 121, 84); color: white; }
    50% { background-color: rgb(95, 185, 125); color: white; }
    100% { background-color:  rgb(65, 121, 84); color: white; }
}


/* Hide laptop view on small screens */
@media (max-width: 767px) {
  .laptop-view {
    display: none !important;
    /* margin-bottom: 0px !important; */
  }
}

/* Hide phone view on larger screens */
@media (min-width: 768px) {
  .phone-view {
    display: none !important;
    /* margin-bottom: 100px !important; */
  }
}


