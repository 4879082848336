/* :root {
    --primary-color: #f53855;
    --white: #ffffff;
    --gray: #efefef;
  } */
  
  body {
    height: 100vh;
    margin: 0;
    font-family: "Poppins", sans-serif;
  }
  
  .container {
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
  }
  
  .form-wrapper {
    margin-top: 50px;
    width: 100%;
    max-width: 400px;
  }
  
  .form {
    width: 300px;
    padding: 1.5rem;
    background-color: white;
    border-radius: 1.5rem;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  }
  
  .input-group {
    position: relative;
    margin: 1rem 10px;
  }
  
  /* .input-group i {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 1.4rem;
    color: var(--gray);
  } */
  
  .input-group input {
    /* width: 100%; */
    padding: 9px;
    font-size: 1rem;
    background-color: #666;
    border-radius: 0.5rem;
    border: 0.125rem solid white;
    outline: none;
  }
  
  .input-group input:focus {
    border: 0.125rem solid orange;
  }
  
  .input-group textarea {
    /* width: 100%; */
    padding: 9px;
    font-size: 1rem;
    background-color: #666;
    border-radius: 0.5rem;
    border: 0.125rem solid white;
    outline: none;
  }
  
  .input-group textarea:focus {
    border: 0.125rem solid orange;
  }
  
  .form button {
    cursor: pointer;
    /* width: 94%; */
    padding: 7px;
    border-radius: 0.5rem;
    border: none;
    background-color: orange;
    color: white;
    font-size: 1.2rem;
    outline: none;
    margin-top: 1rem;
    margin-left: 10px;
  }
  
  .form p {
    margin-top: 1rem;
    font-size: 0.8rem;
    text-align: right;
  }
  
  @media only screen and (max-width: 425px) {
    .form-wrapper {
      margin-top: 30px;
    }
  }
  