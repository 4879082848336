.share-button {
    background-color: #007bff; /* Blue color */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .share-button .fa {
    margin-right: 8px;
    font-size: 18px;
  }
  
  .share-button:hover {
    background-color: #0056b3; 
    transform: scale(1.05);
  }
  
  .share-button:focus {
    outline: none;
  }



  .share-icon {
   
    /* border:1px solid #fdcebd; */
    border-radius: 5px; 
    /* width: 30px; 
    height: 30px;  */
    /* background-color: #fae3da; */
    padding: 2px 10px;
    font-size: 15px;
    /* color: #ff7847;  */
    color: black;
    background-color:#f0f0f0;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
    outline: none;
  }
  
  .share-icon:hover {
    background-color:#ffeee7;
    color: #f75920; /* Darker blue color */
    /* transform: scale(1.2); */
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }