/* body, html {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: serif;
    background-color: #f5f5f5;
  } */
  
  .sip-calculator {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    /* background-color: #fff; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* width: 640px; */
    font-size: 14px;
    /* font-family: serif; */
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
    /* font-family: serif; */
  }
  
  .input-group input[type="range"] {
    width: 100%;
  }
  
  .input-group span {
    /* display: inline-block; */
    margin-top: 10px;
    color: #00A86B;
    font-size: 14px;
    font-weight: bold;
  }
  .sip-btn, .lumpsum-btn {
    padding: 5px 15px;  
    margin: 0 10px;     
    border: none;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sip-btn {
    background-color: #4CAF50; 
    color: white;
    font-family: serif;
  }
  
  .sip-btn:hover {
    background-color: #45a049;
  }
  
  .lumpsum-btn {
    background-color: #008CBA; 
    color: white;
    font-family: serif;
  }
  
  .lumpsum-btn:hover {
    background-color: #007bb5;
  }
  
  .button-container {
    display: flex; 
    align-items: center;
    margin-bottom: 15px;  
    font-size: 16px;
  }
  
  .summary {
    margin-top: 30px;
  }
  
  .summary p {
    margin: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .summary p::before {
    font-weight: bold;   
  }
  
  .invest-btn {
    margin-top: 10px;      
    padding: 10px 20px;    
    background-color: #4caf50; 
    color: white;          
    border: none;         
    border-radius: 5px;    
    cursor: pointer;       
  }
  
  .invest-btn:hover {
    background-color: #218838;
  }

  
  .pie-chart {
    width: 90%;
    height: 230px;
    position: relative;
    margin: auto;
    background: conic-gradient(#4e73df 0% 40%, #36b9cc 40% 100%);
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 40 px;
  }
  
  .pie-chart-part {
    position: absolute;
    border-radius: 50%;
  }

  .summary-above-chart {
    text-align: center;
    margin-top: 10px;
  }

  .input-value {
    color: #00A86B; 
    font-size: 16px; 
    font-weight: bold; 
  }

  .input-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  /* input[type="range"] {
    -webkit-appearance: none; 
    appearance: none;
    width: 100%;
    height: 5px; 
    background: #ddd; 
    border-radius: 5px; 
    outline: none; 
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    width: 12px; 
    height: 12px; 
    background: #4CAF50; 
    border-radius: 50%; 
    cursor: pointer; 
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 20px; 
    height: 20px; 
    background: #4CAF50; 
    border-radius: 50%;
    cursor: pointer; 
  }
  
  span {
    font-weight: bold; 
  }
  */
  






  .mutual-fund-calculator {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 660px;
    font-size: 18px;
    font-family: serif;
  }

  .emi-calculator {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    /* background-color: #fff; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* width: 660px; */
    font-size: 14px;
    /* font-family: serif; */
  }

  





/* #--------------------------------------------------------------------------------------- don't change anything*/

  .swp-calculator {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    /* background-color: #fff; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* width: 580px; */
    font-size: 14px;
    /* font-family: serif; */
  }
  
  /* .input-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  } */


  .responsive-range {
    width: 100%; /* Make it responsive */
    min-width: 200px; /* Set the minimum width */
    max-width: 800px; /* Set the maximum width */
    outline: none;
    /* margin: 0 auto; Center align */
    /* display: block; Center horizontally */
    /* justify-content: start; */
  }
  
  @media (max-width: 768px) {
    .responsive-range {
      min-width: 150px; /* Adjust minimum width for smaller screens */
      max-width: 300px; /* Adjust maximum width for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .responsive-range {
      min-width: 100px; /* Further adjust for very small screens */
      max-width: 250px;
    }
  }
  
  
  /* .summary {
    margin-top: 30px;
  } */



/* #--------------------------------------------------------------------------------------- */
  

  .SukanyaSamriddhiCalculator {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 640px;
    font-size: 18px;
    font-family: serif;
  }


.PPF {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 640px;
  font-size: 18px;
  font-family: serif;
} 

.EPF {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 580px;
  font-size: 18px;
  font-family: serif;
}

.input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.summary {
  margin-top: 30px;
}




.FD {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 640px;
  font-size: 18px;
  font-family: serif;
} 



.RD {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 640px;
  font-size: 18px;
  font-family: serif;
} 



.retirement-calculator {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 700px;
  font-size: 18px;
  font-family: serif;
}

.input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

input[type="radio"] {
  margin-right: 15px; 
}

p span:first-child {
  margin-right: 120px; 
}






.hra-calculator {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 580px;
  font-size: 18px;
  font-family: serif;
}

.input-group {
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
}

.summary {
  margin-top: 30px;
}

.metro-radio-group {
  display: flex;
  align-items: center;
}

.metro-radio-group input {
  margin-right: 10px; 
}

.metro-radio-group span {
  margin-right: 20px;
} 
