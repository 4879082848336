/* Add these styles to your existing CSS file */

.button-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allows buttons to wrap in smaller viewports */
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

.button {
    flex: 1;
    margin: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #777;
    color:#444;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
    background-color: transparent;
    transition: all 0.3s ease; /* Smooth transition for the active state */
    /* Optional: Add styles for buttons */
    outline: none;
}

.button.active {
    /* flex: 1;
    margin: 5px;
    padding: 10px;
    font-size: 16px; */
    border-radius: 10px;
    border: 1px solid #777;
    color:white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    background-color: #777
    /* transition: all 0.3s ease; Smooth transition for the active state */
    /* Optional: Add styles for buttons */
}

@media (max-width: 600px) {
    .button {
        font-size: 12px;
        padding: 8px;
        /* Optional: Adjust button size for mobile */
    }
}



/* src/AnimatedButton.css */
.animated-button {
    /* background-color: #6200ea;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 10px 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
    outline: none;
    position: relative;
    overflow: hidden; */
    flex: 1;
    max-width: 130px;
    margin: 5px;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 5px;
    /* justify-items:end; */
    border: 2px solid #6200ea;
    color:#6200ea;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); */
    background-color: transparent;
    transition: all 0.3s ease; /* Smooth transition for the active state */
    /* Optional: Add styles for buttons */
    outline: none;
  }
  
  .animated-button:hover {
    border-radius: 5px;
    border: 2px solid #6200ea;
    color:white;
    box-shadow: 0px 0px 20px #b8a5cf;
    background-color: #6200ea
    /* border-radius: 10px;
    border: 2px solid #6200ea;
    color:#6200ea;
  
    background-color: transparent; */
  }
  
  @media (max-width: 600px) {
    .animated-button {
        font-size: 10px;
        padding: 5px;
        font-weight: bold;
        border: 1px solid #6200ea;
        /* Optional: Adjust button size for mobile */
    }
}


/* For Webkit browsers (Chrome, Safari, Edge) */
.custom-scrollbar {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 0px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  /* For Firefox */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  
/* global styles.css or a CSS module */
/* .parentDivRef::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  .parentDivRef {
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    -ms-overflow-style: none;
  } */
  

  .carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* margin-top:8px; */
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    min-width: 100%; 
    height: 250px; 
    background-position: center; 
    background-repeat: no-repeat; 
    /* background-size: cover; */
    background-color:#ffb382;
    /* border: none;
    outline: none; */
  }
  
  /* Default styles for arrows */
  .arrow {
    position: absolute;
    top: 50%;
    font-size: 30px;
    transform: translateY(-50%);
    background-color: transparent;
    color: #fff;
    border: none;
    /* border: none; */
    outline: none;
    cursor: pointer;
    padding: 10px;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  
  .arrow:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
  }
  
  .prev {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  
  /* Mobile styles */
  @media (max-width: 1280px) {
    .carousel-item {
      min-width: 100%; 
      margin-top: 8px;
      height: 150px; 
      background-size: contain; 
      background-position: center; 
      background-repeat: no-repeat; 
      border: none;
      outline: none;
    }
    
    .arrow {
      padding: 5px; 
      font-size: 18px; 
    }
  
    .prev {
      left: 5px; 
    }
  
    .next {
      right: 5px; 
    }
  }

  @media (max-width: 700px) {
    .carousel-item {
      min-width: 100%; 
      margin-top: -8px;
      height: 100px; 
      background-size: contain; 
      background-position: center; 
      background-repeat: no-repeat; 
      border: none;
      outline: none;
    }
    
    .arrow {
      padding: 5px; 
      font-size: 18px; 
    }
  
    .prev {
      left: 5px; 
    }
  
    .next {
      right: 5px; 
    }
  }
  







  .spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 8px solid #3498db;
    animation: spin 1s linear infinite;
    /* margin-right: 20px; */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 100vh; */
    margin: 20px;
  }
  

/* 

   .carousel.pointer-event {
    touch-action: pan-y;
}

.carousel {
    position: relative;
}
  .carousel_header {
    background: #CFEDFF;
    padding: 2em 0;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    display: block;
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}
.d-block {
    display: block !important;
}

img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
img {
    max-width: 100%;
    height: auto;
}
img, svg {
    vertical-align: middle;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-indicators.homeCarouselIndicators {
    position: relative;
    margin-bottom: -25px;
}
.align-items-center {
    align-items: center !important;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
}

.carousel-indicators.homeCarouselIndicators {
    position: relative;
    margin-bottom: -25px;
}
.align-items-center {
    align-items: center !important;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
    background-color: var(--color-primary);
}
.carousel-indicators .active {
    opacity: 1;
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.carousel-control-next, .carousel-control-prev {
    color: var(--color-primary);
}
.carousel-control-prev {
    left: 0;
}
.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.carousel-control-next, .carousel-control-prev {
    color: var(--color-primary);
}
.carousel-control-prev {
    left: 0;
}
.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}

@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-prev-icon {
        margin-inline-start: 1.2rem;
        margin-inline-end: auto;
    }
}
@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next-icon, #home_carousel .carousel-control-prev-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    filter: invert(1);
}
 .carousel-control-prev-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e);
} 
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-prev-icon {
        margin-inline-start: 1.2rem;
        margin-inline-end: auto;
    }
}
@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next-icon, #home_carousel .carousel-control-prev-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    filter: invert(1);
}
 .carousel-control-prev-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e);
}  
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}


@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next, #home_carousel .carousel-control-next {
        width: 80px;
    }
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.carousel-control-next, .carousel-control-prev {
    color: var(--color-primary);
}
.carousel-control-next {
    right: 0;
}
.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}
@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next-icon {
        margin-inline-start: auto;
        margin-inline-end: 1.2rem;
    }
}
@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next-icon, #home_carousel .carousel-control-prev-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    filter: invert(1);
}
 .carousel-control-next-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption), .visually-hidden:not(caption) {
    position: absolute !important;
}
.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.active.carousel-item-start, .carousel-item- prev:not(.carousel-item-end)
{
transform: translateX(-100%);
}


@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-prev-icon {
        margin-inline-start: 1.2rem;
        margin-inline-end: auto;
    }
}
@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next-icon, #home_carousel .carousel-control-prev-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    filter: invert(1);
}
 .carousel-control-prev-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e);
} 
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next-icon {
        margin-inline-start: auto;
        margin-inline-end: 1.2rem;
    }
}
@media screen and (max-width: 768px) {
    #home_carousel .carousel-control-next-icon, #home_carousel .carousel-control-prev-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    filter: invert(1);
}
 .carousel-control-next-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
} 
.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}


.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.carousel-control-next, .carousel-control-prev {
    color: var(--color-primary);
}
.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}


.carousel-indicators [data-bs-target] {
    background-color: var(--color-primary);
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}

.carousel-indicators [data-bs-target] {
    background-color: var(--color-primary);
}
.carousel-indicators .active {
    opacity: 1;
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
button, select {
    text-transform: none;
}  */