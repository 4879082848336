/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
  } */


  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* display: flex; */
    /* flex-direction: row;
    flex-wrap: wrap; */
    align-items: start;
    gap: 20px;
    padding: 20px 20px 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    font-family: GrowwSans,NotoSans,system-ui;
    /* width:320px; */
    /* margin: 10px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }

  
  .card h3 {
    margin-top: 10px;
    font-weight: 400px;
    color: #333;
  }

  .card h3 a {
    color: #333;
    text-decoration: none;
    transition: color 0.1s ease; /* Smooth transition on hover */
  }
  
  .card h3 a:hover {
    color: #ff7847 !important;
  }
  
  
  .card p {
    color: #888;
    margin-bottom: 5px;
    line-height: 20px;
  }
  
  .card ul {
    list-style: none;
    padding: 0;
  }
  
  .card ul li {
    margin: 8px 0;
  }
  
  .card ul li a {
    text-decoration: none;
    /* color: #0073e6; */
    color: black;
    font-weight: 400;
    font-size: 15px;
  }
  
  .card ul li a:hover {
    text-decoration: underline;
  }
  
  .toggle-button {
    display: flex;
    align-items: center;
    /* justify-items: center; */
    /* background-color: #0073e6; */
    width:fit-content;
    color: #777;
    /* border: none; */
    /* padding: 10px 15px; */
    /* border-radius: 5px; */
    /* cursor: pointer; */
    margin-top: 10px;
    font-size: 14px;
   font-Weight:400;
  }
  
  .toggle-button:hover {
    color: #ff7847;
  }
  