/* .faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq-question {
    font-weight: bold;
  }
  
  .faq-answer {
    padding: 10px 0;
  }
  
  .MuiAccordion-root {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
   */

 
.faq-container {
    /* max-width: 1200px; */
    /* width:80%; */
    margin: 0 auto 25px auto;
    padding: 20px;
    background: linear-gradient(135deg, #f6f9fc, #e1e6f0);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* position: relative; */
    /* z-index: -1; */
    /* z-index: -2; */
  }
  

  .faq-question {
    font-weight: bold;
    color: #2c3e50;
    font-size: 1rem;
    /* font-size: 13px; */
    /* z-index: 999; */
  }
  
  
  .faq-answer {
    padding: 13px 0 5px 0;
    color: #34495e;
    font-size: 0.85rem;
    /* font-size: 0.85rem; */
    /* z-index: 999; */
  }
  

  .MuiAccordionSummary-root {
    background-color: #3498db;
    color: #ffffff;
    border-radius: 8px;
  }
  

  .MuiAccordionDetails-root {
    /* background-color: #ecf0f1; */
    /* background-color: #dee5f1; */
    background: linear-gradient(135deg, #f6f9fc, #e1e6f0);
    /* border-radius: 0 0 8px 8px; */
    padding: 20px;
  }
  
 
  .Mui-expanded {
    /* background-color: #2980b9; */
  }
  

  .MuiAccordion-root {
    transition: background-color 0.3s ease;
  }
  

  .MuiAccordionSummary-expandIcon {
    color: #ffffff;
    transition: transform 0.3s ease;
  }
  

  .Mui-expanded .MuiAccordionSummary-expandIcon {
    transform: rotate(180deg);
  }


/* 

  .faq-container {
    width: 100%;
  }
  
  .faq-question {
    font-weight: bold;
  }
  
  .faq-answer {
    overflow: hidden;
    transition: height 0.3s ease, opacity 0.3s ease;
  } */
  
  /* .faq-answer.collapsed {
    height: 0;
    opacity: 0;
  }
  
  .faq-answer.expanded {
    height: auto;
    opacity: 1;
  } */
  
  