

/* Default styles for mobile (flex-direction: column) */
.sideBoxContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

.sideBox {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* text-align: center; */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    text-decoration: none;
    transition: box-shadow 0.3s ease;
  }
  
  .sideBox:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .cardTitle {
    font-size: 14px;
    font-weight: bold;
    margin: 0px 0px;
  }
  
  .cardImage {
    width: 100%;
    height: auto;
    object-fit: contain;
    /* margin-bottom: 10px; */
  }
  
  .knowMoreBtn {
    align-self: flex-start;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .knowMoreBtn:hover {
    background-color: #0056b3;
  }
  
  @media (min-width: 768px) {
    .sideBoxContainer {
        flex-direction: row !important;
        flex-wrap: wrap;
      }

    .sideBox {
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
    }
  
    .cardImage {
      width: 100%;
      max-height: 140px;
      
    }
  
    .cardTitle {
      font-size: 14px;
      margin: 0px 0px;
    }
  
    .knowMoreBtn {
      align-self: normal;
      padding: 5px 10px;
    }
  }
  
 /* Tablet and small laptop (768px and up) - row layout */
@media (min-width: 768px) {
    .sideBoxContainer {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  
  /* Larger laptops and desktops (1024px and up) - ensure row layout (1025)*/
  @media (min-width: 1100px) {
    .sideBoxContainer {
      flex-direction: column !important;
    }
  }
  
  /* @media (max-width: 1000px) {
    .sideBoxContainer {
      flex-direction: row !important;
    }
  } */

  /* @media (max-width: 768px) {
    .sideBox {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
  
    .cardImage {
      width: 100%;
      max-height: 140px;
      
    }
  
    .cardTitle {
      font-size: 14px;
      margin: 0px 0px;
    }
  
    .knowMoreBtn {
      align-self: normal;
      padding: 5px 10px;
    }
  } */
  






  .card-body {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .card-body:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .top_row {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
  
  .ipo_cover img {
    border-radius: 4px;
    width:100%;
    max-height:80px;
    /* margin-left: 10px; */
  }
  
  .ipo_name {
    margin-left: 16px;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .broker_content {
    display: flex;
    flex-direction: column;
    /* margin-top: 16px; */
  }
  
  .d-flex {
    /* display: flex; */
    display: flex;
    gap:25px;
    flex-direction: row; 
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .gap-2 {
    gap: 8px;
  }
  
  .gap-3 {
    /* gap: 5px; */
  }
  
  .badge_span {
    background-color: #e0f7fa;
    color: #00796b;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
  }
  
  .viewBtn {
    background-color: transparent;
    color: #00796b;
    border: 1px solid #00796b;
    padding: 4px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    
  }

  .viewBtn:hover {
    /* color: white; */
    color: #00796b;
    /* background-color: #02a18f; */
    box-shadow: inset 0px 0px 50px -21px #00796b;
    /* box-shadow: inset 0px 0px 50px -28px #00796b; */
    border: 1px solid #00796b;
    padding: 4px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    transition: background-color 0.3s ease;
  }

  
  .filledPrimaryBtn {
    background-color: #00796b;
    color: white;
    padding: 4px 16px;
    border-radius: 4px;
    border: none;
    /* font-weight: 600; */
    font-size: 0.875rem;
    outline: none;
  }

  .filledPrimaryBtn:hover {
    background-color: #01534a;
    color: white;
    padding: 4px 16px;
    border-radius: 4px;
    border: none;
    /* font-weight: 600; */
    font-size: 0.875rem;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .data_box {
    /* margin-right: 20px; */
     /* display: flex; */
    /* gap:10px; */
    /* flex-direction: column;    */
     font-size: 14px;
    /* margin-top: 16px; */
  }
  
  .label {
    text-transform: capitalize;
    font-weight: 520;
    margin-bottom: 4px;
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
   
  
    .ipo_name {
      margin-left: 10px;
      /* margin-top: 8px; */
      font-size: 1rem;
    }
  
    .label {
        /* text-transform: capitalize; */
        font-weight: 520;
        font-size: 12px;
        /* margin-bottom: 4px; */
      }


    .d-flex {
        /* display: flex; */
        /* display: flex; */
        gap: 10px;
        /* flex-direction: row;  */
      }
  
    .data_box {
      flex: 1;
      /* margin-right: 15px; */
      /* min-width: 100px; */
      font-size: 13px;
    }
  }
  

























  /* Container for the entire brokerage plans section */
.brokerage-plans {
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  margin: 20px 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Styling for the buttons container */
.plan-buttons {
  display: flex;
  /* align-items: flex-start; */
  gap: 10px;
  margin-bottom: 5px;
}

/* Styling for individual plan buttons */
.plan-button {
  /* background-color: #007BFF; 
  color: #fff; */
   background-color: white;
    color: #00796b;
  /* background-color: white; 
  color: #007BFF; */
  border: 1px solid #00796b;
  padding: 5px 20px;
  border-radius: 8px;
  font-size: 14px;
  /* font-weight: 520; */
  cursor: pointer;
  /* transition: font-size 0.3s ease, background-color 0.3s ease, transform 0.3s ease; */
  transition: background-color 0.3s ease;
  outline: none;
}

.plan-button:hover {
  /* background-color: #0056b3; */
  /* background-color: #e6f1ff;
  color: #007BFF;
  border: 1px solid #007BFF; */
  background-color: #e2f1f0;
  border: 1px solid #00796b;
  color: #00796b;
  /* border: 1px solid #518ecf; */
  /* font-size: 15px;
  transform: scale(1.05); */
}

/* Active state styling for the selected plan button */
.plan-button.active {
  /* background-color: #28a745;  */
  /* color: #fff;
  background-color: #0056b3; */
  border: 1px solid #00796b;

  background-color: #00796b;
  color: white;
}

/* Container for the plan details */
.plan-content {
  /* width: 100%;
  max-width: 900px; */
  /* border-radius: 10px; */
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
  /* background-color: #f9f9f9; */
  padding: 0px 0px 20px 0px;
}

/* Styling for each section within a plan */
.plan-section {
  /* margin-bottom: 20px; */
}

.plan-section-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #666;
  border-bottom: 1px solid #12a08f;
  padding-bottom: 5px;
}

/* Styling for the grid layout of the plan items */
.plan-section-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

/* Styling for each individual plan item */
.plan-item {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styling for the plan item labels and values */
.plan-item-key {
  /* font-weight: 600; */
  font-size: 13.5px;
  color: #888;
}

.plan-item-value {
  /* color: #007BFF; */
  font-size: 13.5px;
  color: black;
  text-align: right;
  margin-left: 10px;
  /* font-weight: 600; */
}

/* Responsive design */
@media (max-width: 600px) {
  /* .plan-buttons {
    flex-direction: row;
    gap: 10px;
  } */

  /* .brokerage-plans {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    margin: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  } */


  /* Container for the plan details */
/* .plan-content {
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  padding: 20px;
} */

  /* Styling for individual plan buttons */
.plan-button {
  padding: 5px 10px;
  font-size: 13px;
}

  .plan-section-title {
    font-size: 14.5px;
  }

  .plan-section-content {
    grid-template-columns: 1fr;
  }

  .plan-item {
    padding: 10px;
  }

  .plan-item-key {
    /* font-weight: 600; */
    /* color: #555; */
    font-size: 13.5px;
  }
  
  .plan-item-value {
    font-size: 14px;
    /* color: #007BFF;
    text-align: right;
    margin-left: 10px; */
    /* font-weight: 600; */
  }
}


/* 

  .brokerage-plans {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f4f4f4;
  }
  
  .plan-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .plan-button {
    flex: 1;
    margin-right: 10px;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .plan-button:last-child {
    margin-right: 0;
  }
  
  .plan-button.active {
    background-color: #0056b3;
  }
  
  .plan-content {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .plan-details {
    padding-top: 10px;
  }
  
  .plan-section {
    margin-bottom: 15px;
  }
  
  .plan-section-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
    text-transform: uppercase;
  }
  
  .plan-section-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .plan-item {
    display: flex;
    justify-content: space-between;
  }
  
  .plan-item-key {
    font-weight: bold;
    color: #555;
  }
  
  .plan-item-value {
    color: #333;
  }
   */




   /* Style for larger screens */
.pros-cons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between columns */
  /* margin: 0 0 8px 0; */
  font-size: 13px;
}

.pros-cons-container > div {
  flex: 1 1 calc(50% - 10px); /* Adjust width of each column */
  padding: 20px;
  box-sizing: border-box; /* Ensure padding does not affect width */
  /* margin: 0 0 8px 0; */
}

.pros {
  background-color: #e0f7fa;
  border-radius: 10px;
}

.cons {
  background-color: #fdecec;
  border-radius: 10px;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .pros-cons-container > div {
    flex: 1 1 100%; /* Full width for mobile devices */
  }
}





/* BrokerCard.css */

.broker-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.broker-card img {
  width: 100px;
  max-height: 100px;
  object-fit: contain;
  margin-bottom: 0px;
}

.broker-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
}

.broker-card-content-column {
  flex-direction: column;
  align-items: center;
}

.broker-name {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.broker-type {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.open-demat-btn {
  text-decoration: none;
  background-color: #00796b;
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: bold;
  margin: 0px 10px;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.open-demat-btn:hover {
  background-color: #01534a;
}

@media (max-width: 768px) {
  .broker-card {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
    gap: 10px;
    /* align-items: center; */
    /* padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff; */
  }

  .broker-card-content {
    display: flex;              /* Ensure this is a flex container */
    flex-direction: column;     /* Stack items vertically */
    align-items: flex-start;    /* Align items to the start of the cross axis */
    /* flex-direction: column;
    align-items: center; */

  }


  .broker-card img {
    width: 90px;
    max-height: 90px;
    object-fit: contain;
    margin-bottom: 0px;
  }

  .broker-name {
    /* margin: 0; */
    font-size: 18px;
    /* color: #333; */
  }

  .broker-type {
    font-size: 13px;
    /* color: #666; */
    margin-top: 2px;
  }

  /* .open-demat-btn {
    margin-left: 0;
    margin-top: 0px;
  } */


  .open-demat-btn {

    margin-left: 0;
    margin-top: 5px;
    margin-right: 0;

    /* text-decoration: none; */
    /* background-color: #00796b; */
    /* color: #fff; */
    font-size: 12px;
    font-weight:normal;
    padding: 4px 10px 6px 10px;
    border-radius: 5px;
    /* font-weight: bold; */
    /* margin: 0px 10px; */
    /* transition: background-color 0.3s ease; */
    /* display: inline-block; */
  }
}
